/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
body{
  margin: 0;
  padding: 0;
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'PT Sans', sans-serif;
}
html{
  scroll-behavior: smooth;
}


  .text-left {
    text-align: left;
  }
  .mb-4 {
    margin-bottom: 1.5rem;
  }
  label {
    display: block;
  }
  input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .bg-blue-500 {
    background-color: #2196f3;
  }
  .hover\:bg-blue-700:hover {
    background-color: #1e88e5;
  }
  .text-white {
    color: #fff;
  }
  .font-bold {
    font-weight: 700;
  }
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .px-4 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .rounded {
    border-radius: 0.25rem;
  }
  @media (min-width: 640px) {
    input {
      width: 50%;
    }
  }

  

  


  